import React from "react";
import "../css/welcome.css";

const Welcome: React.FC = () => {
  return (
    <>
      <div className="welcomeText">Some Welcome Text</div>
    </>
  );
};

export default Welcome;
